import React from "react";
import CalendarCarousel from "./CalendarCarousel";

export default function Calendar() {
  return (
    <div>
      <CalendarCarousel />
    </div>
  );
}
