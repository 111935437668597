import finnovate1 from "../../../img/finnovate1.svg";
import techWorkshop2 from "../../../img/tech-workshop 2.svg";
import finnovate2 from "../../../img/homeVenturesProgram.svg";

export const CARD = [
  {
    key: "finnovate#1",
    img: finnovate1,
    alt: "First image",
  },
  {
    key: "techWorkshop#2",
    img: techWorkshop2,
    alt: "Second image",
  },
  {
    key: "finnovate#2",
    img: finnovate2,
    alt: "Third image",
  },
];
