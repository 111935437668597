import venturesImage from "../../../img/homeVenturesProgram.svg";
import consultingImage from "../../../img/homeConsultingProgram.svg";
import eventsImage from "../../../img/homeEventsProgram.svg";
import tarunAnbarasu from "../../../img/eboard_photos/tarun_anbarasu.png";

export const newsletter = [
  {
    name: "The Rise of Real-Estate Technology in the European Venture Capital Ecosystem",
    date: "November 16, 2023",
    description:
      "A technical report based on research on the rise of Real-Estate Tecnology in Europe",
    authorHeadshot: tarunAnbarasu,
    author: "Tarun Anbarasu",
    img: venturesImage,
    navLink:
      "https://disrupt-fintech.medium.com/financial-report-the-rise-of-real-estate-technology-in-the-european-venture-capital-ecosystem-bec7f2e51c6b",
  },
  {
    name: "CommEx Acquires Binance’s Russian Operations: A Strategic Move in the Crypto Exchange Landscape",
    date: "November 8, 2023",
    description:
      "On September 27, 2023, the renowned cryptocurrency trading platform Binance struck a deal with the newly-launched cryptocurrency exchange, CommEx.",
    authorHeadshot: tarunAnbarasu,
    author: "Anderson Lo",
    img: consultingImage,
    navLink:
      "https://disrupt-fintech.medium.com/commex-acquires-binances-russian-operations-a-strategic-move-in-the-crypto-exchange-landscape-e790b80d9c60",
  },
  {
    name: "Moody’s Adoption of AI: A Game-Changer for the Financial Services Industry",
    date: "October 18, 2023",
    description:
      "AI has the potential to revolutionize the way that financial services are delivered and Moody’s is at the forefront of this revolution.",
    authorHeadshot: tarunAnbarasu,
    author: "Tarun Anbarasu",
    img: eventsImage,
    navLink:
      "https://disrupt-fintech.medium.com/moodys-adoption-of-ai-a-game-changer-for-the-financial-services-industry-0fdc299b6f04",
  },
];
