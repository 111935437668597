// import all of the images for each team member
import riderBishop from "../../../img/eboard_photos/rider_bishop.jpg";
import yvonneLin from "../../../img/eboard_photos/yvonne_lin.png";
import alexChoi from "../../../img/eboard_photos/alex_choi.png";
import xavierYu from "../../../img/eboard_photos/xavier_yu.png";
import kaamilThobani from "../../../img/eboard_photos/kaamil_thobani.png";
import williamSeward from "../../../img/eboard_photos/william_seward.png";
import rodrigoAldrey from "../../../img/eboard_photos/rodrigo_aldrey.png";
import pattyKang from "../../../img/eboard_photos/patty_kang.png";
import anishSanghi from "../../../img/eboard_photos/anish_sanghi.png";
import alexanderWong from "../../../img/eboard_photos/alexander_wong.png";
import sethMorton from "../../../img/eboard_photos/seth_morton.jpg";
import philipLeung from "../../../img/eboard_photos/philip_leung.png";
import aashkaDave from "../../../img/eboard_photos/aashka_dave.png";
import zainabHussainSyed from "../../../img/eboard_photos/zainab_hussain_syed.png";
import anyaSolanki from "../../../img/eboard_photos/anya_solanki.png";
import aravGoyal from "../../../img/eboard_photos/arav_goyal.png";
import michaelRicardo from "../../../img/eboard_photos/michael_ricardo.png";
import wanruSkuldShao from "../../../img/eboard_photos/wanru_skuld_shao.jpg";
import diyaJhamtani from "../../../img/eboard_photos/diya_jhamtani.png";
import emilyKyne from "../../../img/eboard_photos/emily_kyne.png";
import jackAnderson from "../../../img/eboard_photos/jack_anderson.png";
import justinWee from "../../../img/eboard_photos/justin_wee.png";
import tejChakravarthy from "../../../img/eboard_photos/tej_chakravarthy.png";
import jacobChen from "../../../img/eboard_photos/jacob_chen.png";
import srijithGomattam from "../../../img/eboard_photos/srijith_gomattam.png";
import tarunAnbarasu from "../../../img/eboard_photos/tarun_anbarasu.png";
import unknownImage from "../../../img/eboard_photos/unknown.png";

export const TeamMember = [
  {
    key: "member1",
    headshot: riderBishop,
    name: "Rider Bishop",
    position: "President",
    team: "Executive Team",
    linkedin: "https://www.linkedin.com/in/rider-bishop/",
    alt: "",
  },
  {
    key: "member2",
    headshot: philipLeung,
    name: "Philip Leung",
    position: "Vice President",
    team: "Executive Team",
    linkedin: "https://www.linkedin.com/in/philipleungg/",
    alt: "",
  },
  {
    key: "member3",
    headshot: anishSanghi,
    name: "Anish Sanghi",
    position: "Schedueling Coordinator",
    team: "Executive Team",
    linkedin: "https://www.linkedin.com/in/anishsanghi/",
    alt: "",
  },
  {
    key: "member4",
    headshot: xavierYu,
    name: "Xavier Yu",
    position: "Treasurer",
    team: "Executive Team",
    linkedin: "https://www.linkedin.com/in/xavier-yu/",
    alt: "",
  },
  {
    key: "member5",
    headshot: tarunAnbarasu,
    name: "Tarun Anbarasu",
    position: "Director of Research",
    team: "Reserach Team",
    linkedin: "https://www.linkedin.com/in/tarun-anbarasu-107019256/",
    alt: "",
  },
  {
    key: "member6",
    headshot: unknownImage,
    name: "Anderson Lo",
    position: "Research Analyst",
    team: "Research Team",
    linkedin: "https://www.linkedin.com/in/anderson-lo-6714a41ab/",
    alt: "",
  },
  {
    key: "member7",
    headshot: alexanderWong,
    name: "Alexander Wong",
    position: "Research Analyst",
    team: "Research Team",
    linkedin: "https://www.linkedin.com/in/alexwo-ng/",
    alt: "",
  },
  {
    key: "member8",
    headshot: anyaSolanki,
    name: "Anya Solanki",
    position: "Research Analyst",
    team: "Research Team",
    linkedin: "https://www.linkedin.com/in/anyasolanki/",
    alt: "",
  },
  {
    key: "member9",
    headshot: michaelRicardo,
    name: "Michael Ricardo",
    position: "Research Analyst",
    team: "Research Team",
    linkedin: "https://www.linkedin.com/in/michael-ricardo-328957249/",
    alt: "",
  },
  {
    key: "member10",
    headshot: emilyKyne,
    name: "Emily Kyne",
    position: "Research Analyst",
    team: "Research Team",
    linkedin: "https://www.linkedin.com/in/emily-kyne/",
    alt: "",
  },
  {
    key: "member11",
    headshot: wanruSkuldShao,
    name: "Wanru Shao",
    position: "Research Analyst",
    team: "Research Team",
    linkedin: "https://www.linkedin.com/in/skuld-shao/",
    alt: "",
  },
  {
    key: "member12",
    headshot: unknownImage,
    name: "Svaraa Shah",
    position: "Reserach Analyst",
    team: "Research Team",
    linkedin: "https://www.linkedin.com/in/svaraa-shah/",
    alt: "",
  },
  {
    key: "member13",
    headshot: srijithGomattam,
    name: "Srijith Gomattam",
    position: "Research Analyst",
    team: "Research Team",
    linkedin: "https://www.linkedin.com/in/srijith-gomattam/",
    alt: "",
  },
  {
    key: "member14",
    headshot: yvonneLin,
    name: "Yvonne Lin",
    position: "Co-Director of Finnovate",
    team: "Finnovate Team",
    linkedin: "https://www.linkedin.com/in/lin-yvonne/",
    alt: "",
  },
  {
    key: "member15",
    headshot: unknownImage,
    name: "Aden Levy",
    position: "Co-Director of Finnovate",
    team: "Finnovate Team",
    linkedin: "https://www.linkedin.com/in/adenlevy/",
    alt: "",
  },
  {
    key: "member16",
    headshot: alexChoi,
    name: "Alex Choi",
    position: "Co-Director of Finnovate",
    team: "Finnovate Team",
    linkedin: "https://www.linkedin.com/in/alexbhchoi/",
    alt: "",
  },
  {
    key: "member17",
    headshot: tejChakravarthy,
    name: "Tej Chakravarthy",
    position: "Co-Director of Finnovate",
    team: "Finnovate Team",
    linkedin: "https://www.linkedin.com/in/tej-chakravarthy-3b37132a2/",
    alt: "",
  },
  {
    key: "member18",
    headshot: jackAnderson,
    name: "Jack Anderson",
    position: "Director of Events",
    team: "Events Team",
    linkedin: "https://www.linkedin.com/in/jack-anderson-62b236222/",
    alt: "",
  },
  {
    key: "member19",
    headshot: aashkaDave,
    name: "Aashka Dave",
    position: "Event Coordinator",
    team: "Events Team",
    linkedin: "https://www.linkedin.com/in/aashka-dave/",
    alt: "",
  },
  {
    key: "member20",
    headshot: zainabHussainSyed,
    name: "Zainab Hussain Syed",
    position: "Event Coordinator",
    team: "Events Team",
    linkedin: "https://www.linkedin.com/in/zainabhussain03/",
    alt: "",
  },
  {
    key: "member21",
    headshot: justinWee,
    name: "Justin Wee",
    position: "Collab Event Coordinator",
    team: "Events Team",
    linkedin: "https://www.linkedin.com/in/justin-wee-894149259/",
    alt: "",
  },
  {
    key: "member22",
    headshot: kaamilThobani,
    name: "Kaamil Thobani",
    position: "Director of Technology",
    team: "Technology Team",
    linkedin: "https://www.linkedin.com/in/kaamil-thobani-9a7237210/",
    alt: "",
  },
  {
    key: "member23",
    headshot: williamSeward,
    name: "William Seward",
    position: "Technology Programer",
    team: "Technology Team",
    linkedin: "https://www.linkedin.com/in/william-seward-2a04aa262/",
    alt: "",
  },
  {
    key: "member24",
    headshot: unknownImage,
    name: "Arnav Mishra",
    position: "Technology Programmer",
    team: "Technology Team",
    linkedin: "https://www.linkedin.com/in/arnav-mishra-1960001b1/",
    alt: "",
  },
  {
    key: "member25",
    headshot: aravGoyal,
    name: "Arav Goyal",
    position: "Technology Programmer",
    team: "Technology Team",
    linkedin: "https://www.linkedin.com/in/aravgoyal/",
    alt: "",
  },
  {
    key: "member26",
    headshot: diyaJhamtani,
    name: "Diya Jhamtani",
    position: "Technology Programmer",
    team: "Technology Team",
    linkedin: "https://www.linkedin.com/in/diya-jhamtani-a4b763237/",
    alt: "",
  },
  {
    key: "member27",
    headshot: unknownImage,
    name: "Vilasini Nathan",
    position: "Technology Programmer",
    team: "Technology Team",
    linkedin: "https://www.linkedin.com/in/vilasini-nathan/",
    alt: "",
  },
  {
    key: "member28",
    headshot: rodrigoAldrey,
    name: "Rodrigo Aldrey",
    position: "Co-Director of Marketing",
    team: "Marketing",
    linkedin: "https://www.linkedin.com/in/rodrigo-aldrey-558808244/",
    alt: "",
  },
  {
    key: "member29",
    headshot: pattyKang,
    name: "Patty Kang",
    position: "Co-Director of Marketing",
    team: "Marketing Team",
    linkedin: "https://www.linkedin.com/in/peiti-kang-653212250/",
    alt: "",
  },
  {
    key: "member30",
    headshot: unknownImage,
    name: "Siya Patel",
    position: "Student Outreach",
    team: "Marketing Team",
    linkedin: "https://www.linkedin.com/",
    alt: "",
  },
  {
    key: "member31",
    headshot: jacobChen,
    name: "Jacob Chen",
    position: "Industry Outreach",
    team: "Marketing Team",
    linkedin: "https://www.linkedin.com/in/jjacobchen/",
    alt: "",
  },
  {
    key: "member32",
    headshot: sethMorton,
    name: "Seth Morton",
    position: "Director of Engagement",
    team: "Marketing Team",
    linkedin: "https://www.linkedin.com/in/seth-morton-118574242/",
    alt: "",
  },
];
