export const WORKSHOP_CARDS = [
  {
    key: "kickoff1",
    header: "Finnovate Kickoff #1",
    workshopNum: "0.",
    date: "JAN 18 2024",
    startTime: "6:00 PM",
    endTime: "7:00 PM",
  },
  {
    key: "kickoff2",
    header: "Finnovate Kickoff #2",
    workshopNum: "0.",
    date: "JAN 23 2023",
    startTime: "6:00 PM",
    endTime: "8:00 PM",
  },
  {
    key: "workshop1",
    header: "Workshop #1",
    workshopNum: "1.",
    date: "JAN 27 2024",
    startTime: "11:00 AM",
    endTime: "1:00 PM",
  },
  {
    key: "workshop2",
    header: "Workshop #2",
    workshopNum: "2.",
    date: "FEB 3 2024",
    startTime: "11:00 AM",
    endTime: "1:00 PM",
  },
  {
    key: "workshop3",
    header: "Workshop #3",
    workshopNum: "3.",
    date: "FEB 10 2024",
    startTime: "11:00 AM",
    endTime: "1:00 PM",
  },
  {
    key: "workshop4",
    header: "Workshop #4",
    workshopNum: "4.",
    date: "FEB 17 2024",
    startTime: "11:00 AM",
    endTime: "1:00 PM",
  },
  {
    key: "workshop5",
    header: "Workshop #5",
    workshopNum: "5.",
    date: "FEB 24 2024",
    startTime: "11:00 AM",
    endTime: "1:00 PM",
    topic1: "TBD",
  },
  {
    key: "workshop6",
    header: "Workshop #6",
    workshopNum: "6.",
    date: "FEB 29 2024",
    startTime: "6:00 PM",
    endTime: "8:00 PM",
    topic1: "TBD",
  },
  {
    key: "workshop7",
    header: "Workshop #7",
    workshopNum: "7.",
    date: "MAR 16 2024",
    startTime: "11:00 AM",
    endTime: "1:00 PM",
    topic1: "TBD",
  },
  {
    key: "workshop8",
    header: "Workshop #8",
    workshopNum: "8.",
    date: "MAR 23 2024",
    startTime: "11:00 AM",
    endTime: "1:00 PM",
  },
  {
    key: "pitchDay",
    header: "Workshop #",
    workshopNum: "Pitch Day",
    date: "SEP 23 2023",
    startTime: "6:00 PM",
    endTime: "8:00 PM",
    topic1: "Pitch Day!",
  },
];
